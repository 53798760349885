import { InnerpagesContext } from "../context/InnerpagesContext";
import { useContext } from "react";

export const useInnerpagesContext = () => {
    const context = useContext(InnerpagesContext);

    if (!context) {
        return Error('useInnerpagesContext must be used inside an InnerpagesContextProvider');
    };

    return context;
};