import { SustainabilityButtonsContext } from "../context/SustainabilityButtonsContext";
import { useContext } from "react";

export const useSustainabilityButtonsContext = () => {
    const context = useContext(SustainabilityButtonsContext);

    if (!context) {
        return Error('useSustainabilityButtonsContext must be used inside an SustainabilityButtonsContextProvider');
    };

    return context;
};