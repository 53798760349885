import { LinksContext } from "../context/LinksContext";
import { useContext } from "react";

export const useLinksContext = () => {
    const context = useContext(LinksContext);

    if (!context) {
        return Error('useLinksContext must be used inside an LinksContextProvider');
    };

    return context;
};