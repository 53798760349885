import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import "./assets/scss/main.scss"

//Providers
import { UsersContextProvider } from 'context/UsersContext';
import { AuthContextProvider } from './context/AuthContext';
import { LinksContextProvider } from 'context/LinksContext';
import { SlidersContextProvider } from 'context/SlidersContext';
import { FoundationContextProvider } from 'context/FoundationContext';
import { HeadersContextProvider } from 'context/HeadersContext';
import { AboutUsContextProvider } from 'context/AboutUsContext';
import { AboutVideoContextProvider } from 'context/AboutVideoContext';
import { TimelineListContextProvider } from 'context/TimelineListContext';
import { TokenErrorContextProvider } from 'context/TokenErrorContext';
import { PrinciplesContextProvider } from 'context/PrinciplesContext';
import { MediaContextProvider } from 'context/MediaContext';
import { BakliyatContextProvider } from 'context/BakliyatContext';
import { BulgurContextProvider } from 'context/BulgurContext';
import { PirincContextProvider } from 'context/PirincContext';
import { RoyalContextProvider } from 'context/RoyalContext';
import { YieldContextProvider } from 'context/YieldContext';
import { ContactContextProvider } from 'context/ContactContext';
import { SocialResponsibilityContextProvider } from 'context/SocialResponsibilityContext';
import { SustainabilityTextContextProvider } from 'context/SustainabilityTextContext';
import { SustainabilityPhotosContextProvider } from 'context/SustainabilityPhotosContext';
import { SustainabilityButtonsContextProvider } from 'context/SustainabilityButtonsContext';
import { RecipesContextProvider } from 'context/RecipesContext';
import { WhyTextContextProvider } from 'context/WhyTextContext';
import { WhyPhotosContextProvider } from 'context/WhyPhotosContext';
import { CuriousThingsContextProvider } from 'context/CuriousThingsContext';
import { InnerpagesContextProvider } from 'context/InnerpagesContext';
import { AwardsContextProvider } from 'context/AwardsContext';
import { QualityContextProvider } from 'context/QualityContext';
import { HistoryContextProvider } from 'context/HistoryContext';
import { KvkkContextProvider } from 'context/KvkkContext';
import { PrivacyContextProvider } from 'context/PrivacyContext';
import { CookieContextProvider } from 'context/CookieContext';

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthContextProvider>
      <TokenErrorContextProvider>
        <UsersContextProvider>
          <LinksContextProvider>
            <SlidersContextProvider>
              <FoundationContextProvider>
                <HeadersContextProvider>
                  <AboutUsContextProvider>
                    <AboutVideoContextProvider>
                      <TimelineListContextProvider>
                        <PrinciplesContextProvider>
                          <MediaContextProvider>
                            <BakliyatContextProvider>
                              <BulgurContextProvider>
                                <PirincContextProvider>
                                  <RoyalContextProvider>
                                    <YieldContextProvider>
                                      <ContactContextProvider>
                                        <SocialResponsibilityContextProvider>
                                          <SustainabilityTextContextProvider>
                                            <SustainabilityPhotosContextProvider>
                                              <SustainabilityButtonsContextProvider>
                                                <RecipesContextProvider>
                                                  <WhyTextContextProvider>
                                                    <WhyPhotosContextProvider>
                                                      <CuriousThingsContextProvider>
                                                        <InnerpagesContextProvider>
                                                          <AwardsContextProvider>
                                                            <QualityContextProvider>
                                                              <HistoryContextProvider>
                                                                <KvkkContextProvider>
                                                                  <PrivacyContextProvider>
                                                                    <CookieContextProvider>
                                                                      <Router>
                                                                        <App />
                                                                      </Router>
                                                                    </CookieContextProvider>
                                                                  </PrivacyContextProvider>
                                                                </KvkkContextProvider>
                                                              </HistoryContextProvider>
                                                            </QualityContextProvider>
                                                          </AwardsContextProvider>
                                                        </InnerpagesContextProvider>
                                                      </CuriousThingsContextProvider>
                                                    </WhyPhotosContextProvider>
                                                  </WhyTextContextProvider>
                                                </RecipesContextProvider>
                                              </SustainabilityButtonsContextProvider>
                                            </SustainabilityPhotosContextProvider>
                                          </SustainabilityTextContextProvider>
                                        </SocialResponsibilityContextProvider>
                                      </ContactContextProvider>
                                    </YieldContextProvider>
                                  </RoyalContextProvider>
                                </PirincContextProvider>
                              </BulgurContextProvider>
                            </BakliyatContextProvider>
                          </MediaContextProvider>
                        </PrinciplesContextProvider>
                      </TimelineListContextProvider>
                    </AboutVideoContextProvider>
                  </AboutUsContextProvider>
                </HeadersContextProvider>
              </FoundationContextProvider>
            </SlidersContextProvider>
          </LinksContextProvider>
        </UsersContextProvider>
      </TokenErrorContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

