import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PageHeader from "../../components/common/PageHeader";
import { useXhr } from "hooks/useXhr";
import { useSustainabilityButtonsContext } from "hooks/useSustainabilityButtonsContext";
import { Button, Spinner } from "react-bootstrap";
import pages from "../pagesData.json"

function SustainabilityButtons() {
    //States
    const [status, setStatus] = useState(false);
    const [buttonsUpdate, setButtonsUpdate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pagesData, setPagesData] = useState([...pages?.pages]);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr();
    const { sustainabilityButtons, dispatch } = useSustainabilityButtonsContext();

    const requiredFields = ['name1', 'nameEng', 'nameFra', 'nameGer', 'nameDut', 'link'];

    //Functions
    const handleButtonUpdate = (e) => {
        const selectedValue = JSON.parse(e.target.value);

        setButtonsUpdate(prevState => ({
            ...prevState,
            name1: selectedValue.name,
            nameEng: selectedValue.nameEng,
            nameFra: selectedValue.nameFra,
            nameGer: selectedValue.nameGer,
            nameDut: selectedValue.nameDut,
            link: selectedValue.link
        }));
    };

    const generateUniqueFileName = (file) => {
        const timestamp = Date.now();
        const randomNumber = Math.floor(Math.random() * 10000);
        const extension = file.name.split('.').pop();

        return `${timestamp}_${randomNumber}.${extension}`;
    };

    const handleButtonsPhotoInput = (e) => {
        const file = e.target.files[0];
        const id = e.target.id;

        const newFileName = generateUniqueFileName(file);

        const newFile = new File([file], newFileName, { type: file.type });

        setButtonsUpdate(prevState => ({
            ...prevState,
            [id]: newFile
        }));
    };

    const handleButtonsSubmit = async () => {
        for (const field of requiredFields) {
            if (!buttonsUpdate[field] || buttonsUpdate[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'sustainability_buttons');
        formData.append('action', 'update');
        formData.append('id', buttonsUpdate.id);
        formData.append('photo', buttonsUpdate.photo);
        formData.append('name1', buttonsUpdate.name1);
        formData.append('nameEng', buttonsUpdate.nameEng);
        formData.append('nameFra', buttonsUpdate.nameFra);
        formData.append('nameGer', buttonsUpdate.nameGer);
        formData.append('nameDut', buttonsUpdate.nameDut);
        formData.append('link', buttonsUpdate.link);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setButtonsUpdate(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getSustainabilityButtons = async () => {
        const formData = new FormData();
        formData.append('module', 'sustainability_buttons');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            dispatch({ type: 'SET_SUSTAINABILITY_BUTTONS', payload: response });
        };
    };

    useEffect(() => {
        getSustainabilityButtons();
    }, [status]);

    if (sustainabilityButtons) {
        return (
            <div className="container-xxl">
                <PageHeader headerTitle="Sürdürülebilirlik Buton Bölümü" />
                <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12 flex-column">
                        <div className="row g-3 gy-5 py-3 row-deck">

                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className="card customSliders">
                                    <div className="card-body" style={{ marginTop: "0px" }}>
                                        <div className="d-flex align-items-center justify-content-between mt-5">
                                            <div className="lesson_name">
                                                <div className="project-block" style={{ top: "-100px" }}>
                                                    <img
                                                        src={process.env.PUBLIC_URL + `/uploads/sustainability_buttons/${sustainabilityButtons[0].photoName}`}
                                                        style={{
                                                            width: "90%",
                                                            borderRadius: "8px",
                                                            height: "100%",
                                                            objectFit: "cover"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="btn-group" role="group" aria-label="Basic outlined example">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    onClick={() => setButtonsUpdate(sustainabilityButtons[0])}
                                                >
                                                    <i className="icofont-edit text-success"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row g-2 pt-4">
                                            <div className="col-12">
                                                <div className="d-flex align-items-center">
                                                    <div className="text-primary">{"Buton => "}</div>
                                                    <span className="ms-2">{sustainabilityButtons[0].name1}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className="card customSliders">
                                    <div className="card-body" style={{ marginTop: "0px" }}>
                                        <div className="d-flex align-items-center justify-content-between mt-5">
                                            <div className="lesson_name">
                                                <div className="project-block" style={{ top: "-100px" }}>
                                                    <img
                                                        src={process.env.PUBLIC_URL + `/uploads/sustainability_buttons/${sustainabilityButtons[1].photoName}`}
                                                        style={{
                                                            width: "90%",
                                                            height: "100%",
                                                            borderRadius: "8px",
                                                            objectFit: "cover"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="btn-group" role="group" aria-label="Basic outlined example">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    onClick={() => setButtonsUpdate(sustainabilityButtons[1])}
                                                >
                                                    <i className="icofont-edit text-success"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row g-2 pt-4">
                                            <div className="col-12">
                                                <div className="d-flex align-items-center">
                                                    <div className="text-primary">{"Buton => "}</div>
                                                    <span className="ms-2">{sustainabilityButtons[1].name1}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className="card customSliders">
                                    <div className="card-body" style={{ marginTop: "0px" }}>
                                        <div className="d-flex align-items-center justify-content-between mt-5">
                                            <div className="lesson_name">
                                                <div className="project-block" style={{ top: "-100px" }}>
                                                    <img
                                                        src={process.env.PUBLIC_URL + `/uploads/sustainability_buttons/${sustainabilityButtons[2].photoName}`}
                                                        style={{
                                                            width: "90%",
                                                            height: "100%",
                                                            borderRadius: "8px",
                                                            objectFit: "cover"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="btn-group" role="group" aria-label="Basic outlined example">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    onClick={() => setButtonsUpdate(sustainabilityButtons[2])}
                                                >
                                                    <i className="icofont-edit text-success"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row g-2 pt-4">
                                            <div className="col-12">
                                                <div className="d-flex align-items-center">
                                                    <div className="text-primary">{"Buton => "}</div>
                                                    <span className="ms-2">{sustainabilityButtons[2].name1}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    show={buttonsUpdate}
                    onHide={() => {
                        setButtonsUpdate(null);
                        setError({
                            field: null,
                            note: null
                        });
                        setIsLoading(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold">Buton Düzenle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {buttonsUpdate &&
                            <>
                                <div className="g-3 mb-3">
                                    <label htmlFor="button" className="form-label">Buton</label>
                                    <select
                                        id="button"
                                        className="form-select"
                                        onChange={handleButtonUpdate}
                                        style={{ borderColor: `${error.field === "button" ? "red" : "var(--border-color)"}` }}
                                    >
                                        <option value="" hidden>{buttonsUpdate.name1 ? buttonsUpdate.name1 : "Seçiniz..."}</option>
                                        {pagesData.map((option) => (
                                            <option
                                                key={option.name}
                                                value={JSON.stringify({
                                                    name: option.name,
                                                    nameEng: option.nameEng,
                                                    nameFra: option.nameFra,
                                                    nameGer: option.nameGer,
                                                    nameDut: option.nameDut,
                                                    link: option.link
                                                })}
                                            >
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                    {error.field === "name1" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="photo" className="form-label">Fotoğraf Yükle</label>
                                    <input
                                        className="form-control"
                                        type="file"
                                        id="photo"
                                        onChange={handleButtonsPhotoInput}
                                    />
                                    <div className="form-label" style={{ color: "red" }}>
                                        {"Bu alanın geçerli görsel öğe boyutu: 6000x4000"}
                                    </div>
                                </div>
                            </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            className="me-1"
                            onClick={() => handleButtonsSubmit()}
                            disabled={isLoading}
                        >
                            {
                                isLoading ?
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                        Yükleniyor...
                                    </> :
                                    <>
                                        Kaydet
                                    </>
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default SustainabilityButtons;