import { createContext, useReducer } from "react";

export const PrinciplesContext = createContext();

export const principlesReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PRINCIPLES':
            return {
                principles: action.payload
            }
        case 'CREATE_PRINCIPLES':
            return {
                principles: [action.payload, ...state.principles]
            }
        case 'DELETE_PRINCIPLES':
            return {
                principles: state.principles.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const PrinciplesContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(principlesReducer, {
        principles: null
    });

    return (
        <PrinciplesContext.Provider value={{ ...state, dispatch }}>
            {children}
        </PrinciplesContext.Provider>
    );
};