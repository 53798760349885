import { HistoryContext } from "../context/HistoryContext";
import { useContext } from "react";

export const useHistoryContext = () => {
    const context = useContext(HistoryContext);

    if (!context) {
        return Error('useHistoryContext must be used inside an HistoryContextProvider');
    };

    return context;
};