import { SustainabilityPhotosContext } from "../context/SustainabilityPhotosContext";
import { useContext } from "react";

export const useSustainabilityPhotosContext = () => {
    const context = useContext(SustainabilityPhotosContext);

    if (!context) {
        return Error('useSustainabilityPhotosContext must be used inside an SustainabilityPhotosContextProvider');
    };

    return context;
};