import { createContext, useReducer } from "react";

export const TimelineListContext = createContext();

export const timelineListReducer = (state, action) => {
    switch (action.type) {
        case 'SET_TIMELINE_LIST':
            return {
                timelineList: action.payload
            }
        case 'CREATE_TIMELINE_LIST':
            return {
                timelineList: [action.payload, ...state.timelineList]
            }
        case 'DELETE_TIMELINE_LIST':
            return {
                timelineList: state.timelineList.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const TimelineListContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(timelineListReducer, {
        timelineList: null
    });

    return (
        <TimelineListContext.Provider value={{ ...state, dispatch }}>
            {children}
        </TimelineListContext.Provider>
    );
};