import { createContext, useReducer } from "react";

export const HeadersContext = createContext();

export const headersReducer = (state, action) => {
    switch (action.type) {
        case 'SET_HEADERS':
            return {
                headers: action.payload
            }
        case 'CREATE_HEADERS':
            return {
                headers: [action.payload, ...state.headers]
            }
        case 'DELETE_HEADERS':
            return {
                headers: state.headers.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const HeadersContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(headersReducer, {
        headers: null
    });

    return (
        <HeadersContext.Provider value={{ ...state, dispatch }}>
            {children}
        </HeadersContext.Provider>
    );
};