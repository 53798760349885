import { createContext, useReducer } from "react";

export const WhyPhotosContext = createContext();

export const whyPhotosReducer = (state, action) => {
    switch (action.type) {
        case 'SET_WHY_PHOTOS':
            return {
                whyPhotos: action.payload
            }
        case 'CREATE_WHY_PHOTOS':
            return {
                whyPhotos: [action.payload, ...state.whyPhotos]
            }
        case 'DELETE_WHY_PHOTOS':
            return {
                whyPhotos: state.whyPhotos.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const WhyPhotosContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(whyPhotosReducer, {
        whyPhotos: null
    });

    return (
        <WhyPhotosContext.Provider value={{ ...state, dispatch }}>
            {children}
        </WhyPhotosContext.Provider>
    );
};