import { createContext, useReducer } from "react";

export const SustainabilityPhotosContext = createContext();

export const sustainabilityPhotosReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SUSTAINABILITY_PHOTOS':
            return {
                sustainabilityPhotos: action.payload
            }
        case 'CREATE_SUSTAINABILITY_PHOTOS':
            return {
                sustainabilityPhotos: [action.payload, ...state.sustainabilityPhotos]
            }
        case 'DELETE_SUSTAINABILITY_PHOTOS':
            return {
                sustainabilityPhotos: state.sustainabilityPhotos.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const SustainabilityPhotosContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(sustainabilityPhotosReducer, {
        sustainabilityPhotos: null
    });

    return (
        <SustainabilityPhotosContext.Provider value={{ ...state, dispatch }}>
            {children}
        </SustainabilityPhotosContext.Provider>
    );
};