import { createContext, useReducer } from "react";

export const PirincContext = createContext();

export const pirincReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PIRINC':
            return {
                pirinc: action.payload
            }
        case 'CREATE_PIRINC':
            return {
                pirinc: [action.payload, ...state.pirinc]
            }
        case 'DELETE_PIRINC':
            return {
                pirinc: state.pirinc.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const PirincContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(pirincReducer, {
        pirinc: null
    });

    return (
        <PirincContext.Provider value={{ ...state, dispatch }}>
            {children}
        </PirincContext.Provider>
    );
};