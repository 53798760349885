import { AboutUsContext } from "../context/AboutUsContext";
import { useContext } from "react";

export const useAboutUsContext = () => {
    const context = useContext(AboutUsContext);

    if (!context) {
        return Error('useAboutUsContext must be used inside an AboutUsContextProvider');
    };

    return context;
};