import { PrinciplesContext } from "../context/PrinciplesContext";
import { useContext } from "react";

export const usePrinciplesContext = () => {
    const context = useContext(PrinciplesContext);

    if (!context) {
        return Error('usePrinciplesContext must be used inside an PrinciplesContextProvider');
    };

    return context;
};