import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useXhr } from "hooks/useXhr";
import { useLinksContext } from "hooks/useLinksContext";
import { Button, Spinner } from "react-bootstrap";

//images
import Facebook from "../../assets/images/logos/Facebook.png"
import Xlogo from "../../assets/images/logos/Xlogo.png"
import Instagram from "../../assets/images/logos/Instagram.png"
import Youtube from "../../assets/images/logos/Youtube.png"

function SocialMediaLinks() {

    //States
    const [status, setStatus] = useState(false);
    const [update, setUpdate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr()
    const { links, dispatch } = useLinksContext();

    const requiredFields = ['name', 'link'];

    const handleImage = (id) => {
        if (id === "1" && Facebook) {
            return Facebook
        } else if (id === "2" && Xlogo) {
            return Xlogo
        } else if (id === "3" && Instagram) {
            return Instagram
        } else if (id === "4" && Youtube) {
            return Youtube
        }
    }

    const handleOnChange = (e) => {
        const { value } = e.target;

        setUpdate(prevState => ({
            ...prevState,
            link: value
        }));
    };

    const handleSubmit = async () => {
        for (const field of requiredFields) {
            if (!update[field] || update[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'links');
        formData.append('action', 'update');
        formData.append('id', update.id);
        formData.append('name', update.name);
        formData.append('link', update.link);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setUpdate(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getLinks = async () => {
        const formData = new FormData();
        formData.append('module', 'links');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            dispatch({ type: 'SET_LINKS', payload: response });
        };
    };

    useEffect(() => {
        getLinks();
    }, [status]);

    return (
        <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12">
                {links &&
                    links.map((d, i) => {
                        return <div key={"keke" + i} className="card mb-3">
                            <div className="card-body d-sm-flex justify-content-between">
                                <div className="d-flex">
                                    <img className="avatar rounded" src={handleImage(d.id)} alt="" />
                                    <div className="flex-fill ms-3 text-truncate">
                                        <h6 className="d-flex justify-content-between mb-0">
                                            <span>{d.name}</span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="text-end d-none d-md-block">
                                    <p className="mb-1">
                                        {d.link}
                                    </p>
                                </div>
                            </div>
                            <div className="card-footer justify-content-between d-flex align-items-center">
                                <div className="d-none d-md-block">
                                    <strong>Son Güncellenme Tarihi:</strong>
                                    <span>{" " + d.updatedAt}</span>
                                </div>
                                <div className="card-hover-show">
                                    <button
                                        className="btn btn-sm btn-primary border lift"
                                        onClick={() => setUpdate(d)}
                                    >
                                        Düzenle
                                    </button>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
            <Modal
                show={update}
                onHide={() => {
                    setUpdate(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Link</label>
                        <input
                            type="text"
                            className="form-control"
                            id={update && update.id}
                            value={update && update.link}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "link" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "link" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}


export default SocialMediaLinks;