import { RecipesContext } from "../context/RecipesContext";
import { useContext } from "react";

export const useRecipesContext = () => {
    const context = useContext(RecipesContext);

    if (!context) {
        return Error('useRecipesContext must be used inside an RecipesContextProvider');
    };

    return context;
};