import { createContext, useReducer } from "react";

export const BakliyatContext = createContext();

export const bakliyatReducer = (state, action) => {
    switch (action.type) {
        case 'SET_BAKLIYAT':
            return {
                bakliyat: action.payload
            }
        case 'CREATE_BAKLIYAT':
            return {
                bakliyat: [action.payload, ...state.bakliyat]
            }
        case 'DELETE_BAKLIYAT':
            return {
                bakliyat: state.bakliyat.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const BakliyatContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(bakliyatReducer, {
        bakliyat: null
    });

    return (
        <BakliyatContext.Provider value={{ ...state, dispatch }}>
            {children}
        </BakliyatContext.Provider>
    );
};