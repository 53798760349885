import React from "react";
import { Link } from "react-router-dom";
import menu from "../Data/menu.json";
import { useState } from "react";
import { useEffect } from "react";

//images
import Logo from "../../assets/images/logos/ReisLogo.svg"
import NovusIDLogo from "../../assets/images/logos/NovusIDLogo1.svg"

function Sidebar(props) {

    const [isSidebarMini, setIsSidebarMini] = useState(false);
    const [menuData, setMenuData] = useState([...menu?.menu]);
    const [darkLightMode, setDarkLightMode] = useState("light");
    const [updateRtl, setUpdateRtl] = useState(false);

    useEffect(() => {
        window.document.children[0].setAttribute("data-theme", "light");

    }, []);

    function openChildren(id) {
        var otherTabs = document.getElementsByClassName("has-children")
        if (otherTabs) {
            for (var i = 0; i < otherTabs.length; i++) {
                if (otherTabs[i].id !== id) {
                    otherTabs[i].className = otherTabs[i].className.replace(" show", "");
                    if (otherTabs[i].parentElement.children.length > 1) {
                        otherTabs[i].parentElement.children[0].setAttribute("aria-expanded", "false")
                    }
                }
            }
        }
        var menutab = document.getElementById(id)
        if (menutab) {
            if (menutab.classList.contains("show")) {
                menutab.classList.remove("show")
                if (menutab.parentElement.children.length > 1) {
                    menutab.parentElement.children[0].setAttribute("aria-expanded", "false")
                }
            } else {
                menutab.classList.add("show")
                if (menutab.parentElement.children.length > 1) {
                    menutab.parentElement.children[0].setAttribute("aria-expanded", "true")
                }
            }
        }
    }
    function openChildren1(id) {
        var otherTabs = document.getElementsByClassName("has-children")
        if (otherTabs) {
            for (var i = 0; i < otherTabs.length; i++) {
                otherTabs[i].className = otherTabs[i].className.replace(" show", "");
            }
        }
        var menutab = document.getElementById(id)
        if (menutab) {
            menutab.classList.add("show")
            if (menutab.parentElement.children.length > 1) {
                menutab.parentElement.children[0].setAttribute("aria-expanded", "true")
            }
        }
    }
    function closeChildren() {
        var otherTabs = document.getElementsByClassName("has-children")
        if (otherTabs) {
            for (var i = 0; i < otherTabs.length; i++) {
                otherTabs[i].className = otherTabs[i].className.replace(" show", "");
                if (otherTabs[i].parentElement.children.length > 1) {
                    otherTabs[i].parentElement.children[0].setAttribute("aria-expanded", "false")
                }
            }
        }
    }
    function GotoChangeMenu(val) {
        props.history.push("hr-dashboard");
        setMenuData([...menu.menu]);
    }
    function onChangeDarkMode() {
        if (window.document.children[0].getAttribute("data-theme") === "light") {
            window.document.children[0].setAttribute("data-theme", "dark")
            setDarkLightMode("dark");
        } else {
            window.document.children[0].setAttribute("data-theme", "light")
            setDarkLightMode("light")

        }
    }
    function onChangeRTLMode() {
        if (document.body.classList.contains("rtl_mode")) {
            document.body.classList.remove("rtl_mode");
        } else {
            document.body.classList.add("rtl_mode");
        }
        setUpdateRtl(!updateRtl);
    }
    const { activekey } = props;
    return (
        <div id="mainSideMenu" className={`sidebar px-4 py-4 py-md-5 me-0 ${isSidebarMini ? "sidebar-mini" : ""}`}>
            <div className="d-flex flex-column h-100">
                <a href="hr-dashboard" className="mb-0 brand-icon">
                    <span className="logo-text">
                        <img src={Logo} alt="" style={{ width: "100%" }} />
                    </span>
                </a>
                <ul className="menu-list flex-grow-1 mt-3">
                    {
                        menuData.map((d, i) => {
                            if (d.isToggled) {
                                return <li key={"shsdg" + i}>
                                    <a className={`m-link `} href="#!" onClick={(e) => { e.preventDefault(); GotoChangeMenu(d.name); }}>
                                        <i className={d.iconClass}></i>
                                        <span>{d.name}</span>
                                    </a>
                                </li>
                            }
                            if (d.children.length === 0) {
                                return <li key={"dsfshsdg" + i} className=" collapsed">
                                    <Link to={d.routerLink[0]} className={`m-link ${(d.routerLink[0] === activekey) ? "active" : ""}`} >
                                        <i className={d.iconClass}></i>
                                        <span>{d.name}</span>
                                    </Link>
                                </li>
                            }
                            return <li key={"shsdg" + i} className=" collapsed">
                                <a className={`m-link ${d.children.filter((d) => "/" + d.routerLink[0] === activekey).length > 0 ? "active" : ""}`} href="#!" onClick={(e) => { e.preventDefault(); openChildren("menu-Pages" + i) }}>
                                    <i className={d.iconClass}></i>
                                    <span>{d.name}</span>
                                    <span className="arrow icofont-dotted-down ms-auto text-end fs-5">
                                    </span>
                                </a>
                                {
                                    d.children.length > 0 ?
                                        <ul className="sub-menu collapse has-children" id={"menu-Pages" + i}>
                                            {d.children.map((data, ind) => {
                                                if (d.children.length > 0) {
                                                    if (activekey === "/" + data.routerLink[0]) {
                                                        setTimeout(() => {
                                                            openChildren1("menu-Pages" + i)
                                                        }, 500);
                                                    }

                                                }
                                                return <li key={"jfdgj" + ind}>
                                                    <Link className={activekey === "/" + data.routerLink[0] ? "ms-link active" : "ms-link"} to={data.routerLink[0]}> <span>{data.name}</span></Link>
                                                </li>

                                            })}
                                        </ul>
                                        : null
                                }

                            </li>
                        })
                    }


                </ul>
                <ul className="list-unstyled mb-0">
                    <li className="d-flex align-items-center justify-content-center">
                        <div className="form-check form-switch theme-switch">
                            <input className="form-check-input" type="checkbox" checked={darkLightMode === "dark" ? true : false} id="theme-switch" onChange={() => { onChangeDarkMode(); }} />
                            <label className="form-check-label" htmlFor="theme-switch">Karanlık Tema</label>
                        </div>
                    </li>
                </ul>
                <span className="logo-text d-flex justify-content-center">
                    <img src={NovusIDLogo} alt="" style={{ width: "60%", margin: "20px" }} />
                </span>
            </div>
        </div>
    )
}


export default Sidebar