import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PageHeader from "../../../components/common/PageHeader";
import Slider from "./Slider";
import { useXhr } from "hooks/useXhr";
import { useSlidersContext } from "hooks/useSlidersContext";
import { Button, Spinner } from "react-bootstrap";
import pages from "../../pagesData.json"

function SmallSliders() {
    //States
    const [status, setStatus] = useState(false);
    const [isUpdate, setIsUpdate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [lang, setLang] = useState(null);
    const [pagesData, setPagesData] = useState([...pages?.pages]);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr()
    const { sliders, dispatch } = useSlidersContext();

    const requiredFields = ['alt', 'title', 'firstButtonText', 'firstButtonLink', 'secondButtonText', 'secondButtonLink', 'active'];

    const handleFirstButtonUpdate = (e) => {
        const selectedValue = JSON.parse(e.target.value);

        setIsUpdate(prevState => ({
            ...prevState,
            firstButtonText: selectedValue.name,
            firstButtonLink: selectedValue.link
        }));
    };

    const handleSecondButtonUpdate = (e) => {
        const selectedValue = JSON.parse(e.target.value);

        setIsUpdate(prevState => ({
            ...prevState,
            secondButtonText: selectedValue.name,
            secondButtonLink: selectedValue.link
        }));
    };

    const handleOnChange = (e) => {
        const { id, value } = e.target;

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const generateUniqueFileName = (file) => {
        const timestamp = Date.now();
        const randomNumber = Math.floor(Math.random() * 10000);
        const extension = file.name.split('.').pop();

        return `${timestamp}_${randomNumber}.${extension}`;
    };

    const handlePhotoInput = (e) => {
        const file = e.target.files[0];
        const id = e.target.id;

        const newFileName = generateUniqueFileName(file);
        const renamedFile = new File([file], newFileName, { type: file.type });

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: renamedFile
        }));
    };

    const handleSubmit = async () => {
        for (const field of requiredFields) {
            if (!isUpdate[field] || isUpdate[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'sliders');
        formData.append('action', 'update');
        formData.append('id', isUpdate.id);
        formData.append('photo', isUpdate.photo);
        formData.append('alt', isUpdate.alt);
        formData.append('title', isUpdate.title);
        formData.append('titleEng', isUpdate.titleEng);
        formData.append('titleFra', isUpdate.titleFra);
        formData.append('titleGer', isUpdate.titleGer);
        formData.append('titleDut', isUpdate.titleDut);
        formData.append('firstButtonText', isUpdate.firstButtonText);
        formData.append('firstButtonLink', isUpdate.firstButtonLink);
        formData.append('secondButtonText', isUpdate.secondButtonText);
        formData.append('secondButtonLink', isUpdate.secondButtonLink);
        formData.append('active', isUpdate.active);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setIsUpdate(null);
            setStatus(!status);
            setLang(null);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getSliders = async () => {
        const formData = new FormData();
        formData.append('module', 'sliders');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            dispatch({ type: 'SET_SLIDERS', payload: response.slice(9, 18) });
        };
    };

    useEffect(() => {
        getSliders();
    }, [status]);

    return (
        <div className="container-xxl">
            <PageHeader headerTitle="Küçük Ekran Sliderları" />
            <div className="row align-items-center">
                <div className="col-lg-12 col-md-12 flex-column">
                    <div className="row g-3 gy-5 py-3 row-deck">
                        {sliders &&
                            sliders.map((d, i) => {
                                return <div
                                    key={"ljsdhl" + i}
                                    className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6"
                                >
                                    <Slider
                                        id={d.id}
                                        photoName={d.photoName}
                                        alt={d.alt}
                                        title={d.title}
                                        firstButtonText={d.firstButtonText}
                                        firstButtonLink={d.firstButtonLink}
                                        secondButtonText={d.secondButtonText}
                                        secondButtonLink={d.secondButtonLink}
                                        active={d.active}
                                        onClickEdit={() => { setIsUpdate(d); setLang("tur") }}
                                        onClickEditEng={() => { setIsUpdate(d); setLang("eng") }}
                                        onClickEditFra={() => { setIsUpdate(d); setLang("fra") }}
                                        onClickEditGer={() => { setIsUpdate(d); setLang("ger") }}
                                        onClickEditDut={() => { setIsUpdate(d); setLang("dut") }}
                                    />
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
            <Modal
                show={lang === "tur"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Slider Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isUpdate &&
                        <>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Başlık</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="title"
                                    value={isUpdate && isUpdate.title}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "title" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "title" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="row g-3 mb-3">
                                <div className="col-sm">
                                    <label htmlFor="firstButton" className="form-label">1.Buton</label>
                                    <select
                                        id="firstButton"
                                        className="form-select"
                                        onChange={handleFirstButtonUpdate}
                                        style={{ borderColor: `${error.field === "firstButton" ? "red" : "var(--border-color)"}` }}
                                    >
                                        <option value="" hidden>{isUpdate.firstButtonText ? isUpdate.firstButtonText : "Seçiniz..."}</option>
                                        {pagesData.map((option) => (
                                            <option key={option.name} value={JSON.stringify({ name: option.name, link: option.link })}>{option.name}</option>
                                        ))}
                                    </select>
                                    {error.field === "firstButtonText" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="secondButton" className="form-label">2.Buton</label>
                                    <select
                                        id="secondButton"
                                        className="form-select"
                                        onChange={handleSecondButtonUpdate}
                                        style={{ borderColor: `${error.field === "secondButton" ? "red" : "var(--border-color)"}` }}
                                    >
                                        <option value="" hidden>{isUpdate.secondButtonText ? isUpdate.secondButtonText : "Seçiniz..."}</option>
                                        {pagesData.map((option) => (
                                            <option key={option.name} value={JSON.stringify({ name: option.name, link: option.link })}>{option.name}</option>
                                        ))}
                                    </select>
                                    {error.field === "secondButtonText" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="alt" className="form-label">
                                    {"Seo Kelimeler "}
                                    <span className="text-danger">
                                        (Lütfen 5 Kelimeden Fazla Yazmayınız!)
                                    </span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="alt"
                                    value={isUpdate && isUpdate.alt}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "alt" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "alt" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="row g-3 mb-3">
                                <div className="col-sm-8">
                                    <label htmlFor="photo" className="form-label">Fotoğraf Yükle</label>
                                    <input
                                        className="form-control"
                                        type="file"
                                        id="photo"
                                        onChange={handlePhotoInput}
                                    />
                                    <div className="form-label" style={{ color: "red" }}>
                                        {"Bu alanın geçerli görsel öğe boyutu: 1496x850"}
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label htmlFor="active" className="form-label">Aktivasyon</label>
                                    <select
                                        id="active"
                                        className="form-select"
                                        onChange={handleOnChange}
                                        style={{ borderColor: `${error.field === "active" ? "red" : "var(--border-color)"}` }}
                                    >
                                        {
                                            isUpdate.active === "true" &&
                                            <>
                                                <option value={"true"} selected>Aktif</option>
                                                <option value={"false"}>Pasif</option>
                                            </>
                                        }
                                        {
                                            isUpdate.active === "false" &&
                                            <>
                                                <option value={"true"}>Aktif</option>
                                                <option value={"false"} selected>Pasif</option>
                                            </>
                                        }
                                    </select>
                                    {error.field === "active" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "eng"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">İngilizce Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleEng" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleEng"
                            value={isUpdate && isUpdate.titleEng}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "titleEng" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleEng" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "fra"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Fransızca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleFra" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleFra"
                            value={isUpdate && isUpdate.titleFra}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "titleFra" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleFra" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "ger"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Almanca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleGer" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleGer"
                            value={isUpdate && isUpdate.titleGer}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "titleGer" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleGer" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "dut"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Flemenkçe Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleDut" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleDut"
                            value={isUpdate && isUpdate.titleDut}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "titleDut" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleDut" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SmallSliders;