import { createContext, useReducer } from "react";

export const QualityContext = createContext();

export const qualityReducer = (state, action) => {
    switch (action.type) {
        case 'SET_QUALITY':
            return {
                quality: action.payload
            }
        case 'CREATE_QUALITY':
            return {
                quality: [action.payload, ...state.quality]
            }
        case 'DELETE_QUALITY':
            return {
                quality: state.quality.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const QualityContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(qualityReducer, {
        quality: null
    });

    return (
        <QualityContext.Provider value={{ ...state, dispatch }}>
            {children}
        </QualityContext.Provider>
    );
};