import { createContext, useReducer } from "react";

export const AboutUsContext = createContext();

export const aboutUsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ABOUT_US':
            return {
                aboutUs: action.payload
            }
        case 'CREATE_ABOUT_US':
            return {
                aboutUs: [action.payload, ...state.aboutUs]
            }
        case 'DELETE_ABOUT_US':
            return {
                aboutUs: state.aboutUs.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const AboutUsContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(aboutUsReducer, {
        aboutUs: null
    });

    return (
        <AboutUsContext.Provider value={{ ...state, dispatch }}>
            {children}
        </AboutUsContext.Provider>
    );
};