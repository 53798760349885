import { YieldContext } from "../context/YieldContext";
import { useContext } from "react";

export const useYieldContext = () => {
    const context = useContext(YieldContext);

    if (!context) {
        return Error('useYieldContext must be used inside an YieldContextProvider');
    };

    return context;
};