import { FoundationContext } from "../context/FoundationContext";
import { useContext } from "react";

export const useFoundationContext = () => {
    const context = useContext(FoundationContext);

    if (!context) {
        return Error('useFoundationContext must be used inside an FoundationContextProvider');
    };

    return context;
};