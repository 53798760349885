import { createContext, useReducer } from "react";

export const CuriousThingsContext = createContext();

export const curiousThingsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_CURIOUS_THINGS':
            return {
                curiousThings: action.payload
            }
        case 'CREATE_CURIOUS_THINGS':
            return {
                curiousThings: [action.payload, ...state.curiousThings]
            }
        case 'DELETE_CURIOUS_THINGS':
            return {
                curiousThings: state.curiousThings.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const CuriousThingsContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(curiousThingsReducer, {
        curiousThings: null
    });

    return (
        <CuriousThingsContext.Provider value={{ ...state, dispatch }}>
            {children}
        </CuriousThingsContext.Provider>
    );
};