import { AboutVideoContext } from "../context/AboutVideoContext";
import { useContext } from "react";

export const useAboutVideoContext = () => {
    const context = useContext(AboutVideoContext);

    if (!context) {
        return Error('useAboutVideoContext must be used inside an AboutVideoContextProvider');
    };

    return context;
};