import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PageHeader from "../../components/common/PageHeader";
import { useXhr } from "hooks/useXhr";
import { useCookieContext } from "hooks/useCookieContext";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button, Spinner } from "react-bootstrap";

function Cookie() {
    //States
    const [status, setStatus] = useState(false);
    const [isUpdate, setIsUpdate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [lang, setLang] = useState(null);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr()
    const { cookie, dispatch } = useCookieContext();

    const requiredFields = ['title', 'text1'];

    const handleTextInputUpdate = (e) => {
        if (lang === "tur") {
            setIsUpdate(prevState => ({
                ...prevState,
                text1: e
            }));
        } else if (lang === "eng") {
            setIsUpdate(prevState => ({
                ...prevState,
                textEng: e
            }));
        } else if (lang === "fra") {
            setIsUpdate(prevState => ({
                ...prevState,
                textFra: e
            }));
        } else if (lang === "ger") {
            setIsUpdate(prevState => ({
                ...prevState,
                textGer: e
            }));
        } else if (lang === "dut") {
            setIsUpdate(prevState => ({
                ...prevState,
                textDut: e
            }));
        }
    };

    const handleOnUpdateChange = (e) => {
        const { id, value } = e.target;

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleUpdate = async () => {
        for (const field of requiredFields) {
            if (!isUpdate[field] || isUpdate[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'cookie');
        formData.append('action', 'update');
        formData.append('id', isUpdate.id);
        formData.append('title', isUpdate.title);
        formData.append('text1', isUpdate.text1);
        formData.append('titleEng', isUpdate.titleEng);
        formData.append('textEng', isUpdate.textEng);
        formData.append('titleFra', isUpdate.titleFra);
        formData.append('textFra', isUpdate.textFra);
        formData.append('titleGer', isUpdate.titleGer);
        formData.append('textGer', isUpdate.textGer);
        formData.append('titleDut', isUpdate.titleDut);
        formData.append('textDut', isUpdate.textDut);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setIsUpdate(null);
            setLang(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getCookie = async () => {
        const formData = new FormData();
        formData.append('module', 'cookie');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            dispatch({ type: 'SET_COOKIE', payload: response });
        };
    };

    useEffect(() => {
        getCookie();
    }, [status]);

    //Columns
    var columnT = ""

    columnT = [
        {
            name: "BAŞLIK",
            selector: (row) => row.title,
            sortable: true,
            maxWidth: "150px",
            cell: (row) => <a href="#" className="fw-bold text-primary">{row.title}</a>
        },
        {
            name: "YAZI",
            selector: (row) => row.text1,
            sortable: true,
            maxWidth: "57vw",
            cell: (row) => {
                const maxLength = 200;
                const text = row.text1.length > maxLength ? `${row.text1.slice(0, maxLength)}...` : row.text1;
                return <div dangerouslySetInnerHTML={{ __html: text }} />;
            }
        },
        {
            name: "AKSİYON",
            selector: (row) => { },
            sortable: true,
            maxWidth: "120px",
            cell: (row) => <div className="btn-group" role="group" aria-label="Basic outlined example">
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Türkçe Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("tur"); }}
                >
                    <i className="icofont-edit text-success"></i>
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="İngilizce Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("eng"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/united-kingdom.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Fransızca Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("fra"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/france.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Almanca Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("ger"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/germany.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Flemenkçe Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("dut"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/netherlands.png`} style={{ width: "15px" }} />
                </button>
            </div>
        }
    ]

    return (
        <div className="container-xxl">
            <PageHeader headerTitle="Çerez Politikası" />
            <div className="row clearfix g-3">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            {cookie &&
                                <DataTable
                                    title="Cookie"
                                    columns={columnT}
                                    data={cookie}
                                    defaultSortField="title"
                                    pagination
                                    selectableRows={false}
                                    className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                                    highlightOnHover={true}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={lang === "tur"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Sayfayı Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={isUpdate && isUpdate.title}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "title" ? "red" : "var(--border-color)"}` }}
                        />
                        {error.field === "title" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="text1" className="form-label">
                            {"Yazı"}
                        </label>
                        {
                            isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="text1"
                                rows="10"
                                value={isUpdate.text1}
                                onChange={handleTextInputUpdate}
                                style={{ borderColor: `${error.field === "text1" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ size: [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                    ],
                                }}
                            />
                        }
                        {error.field === "text1" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "eng"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">İngilizce Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleEng" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleEng"
                            value={isUpdate && isUpdate.titleEng}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "titleEng" ? "red" : "var(--border-color)"}` }}
                        />
                        {error.field === "titleEng" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="textEng" className="form-label">
                            {"Yazı"}
                        </label>
                        {
                            isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="textEng"
                                rows="10"
                                value={isUpdate.textEng}
                                onChange={handleTextInputUpdate}
                                style={{ borderColor: `${error.field === "textEng" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ size: [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                    ],
                                }}
                            />
                        }
                        {error.field === "textEng" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "fra"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Fransızca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleFra" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleFra"
                            value={isUpdate && isUpdate.titleFra}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "titleFra" ? "red" : "var(--border-color)"}` }}
                        />
                        {error.field === "titleFra" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="textFra" className="form-label">
                            {"Yazı"}
                        </label>
                        {
                            isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="textFra"
                                rows="10"
                                value={isUpdate.textFra}
                                onChange={handleTextInputUpdate}
                                style={{ borderColor: `${error.field === "textFra" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ size: [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                    ],
                                }}
                            />
                        }
                        {error.field === "textFra" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "ger"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Almanca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleGer" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleGer"
                            value={isUpdate && isUpdate.titleGer}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "titleGer" ? "red" : "var(--border-color)"}` }}
                        />
                        {error.field === "titleGer" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="textGer" className="form-label">
                            {"Yazı"}
                        </label>
                        {
                            isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="textGer"
                                rows="10"
                                value={isUpdate.textGer}
                                onChange={handleTextInputUpdate}
                                style={{ borderColor: `${error.field === "textGer" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ size: [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                    ],
                                }}
                            />
                        }
                        {error.field === "textGer" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "dut"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Flemenkçe Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleDut" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleDut"
                            value={isUpdate && isUpdate.titleDut}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "titleDut" ? "red" : "var(--border-color)"}` }}
                        />
                        {error.field === "titleDut" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="textDut" className="form-label">
                            {"Yazı"}
                        </label>
                        {
                            isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="textDut"
                                rows="10"
                                value={isUpdate.textDut}
                                onChange={handleTextInputUpdate}
                                style={{ borderColor: `${error.field === "textDut" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ size: [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                    ],
                                }}
                            />
                        }
                        {error.field === "textDut" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Cookie;