import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PageHeader from "../../../components/common/PageHeader";
import { useXhr } from "hooks/useXhr";
import { useFoundationContext } from "hooks/useFoundationContext";
import { Button, Spinner } from "react-bootstrap";
import pages from "../../pagesData.json"

function Foundation() {
    //States
    const [status, setStatus] = useState(false);
    const [update, setUpdate] = useState(null);
    const [lang, setLang] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pagesData, setPagesData] = useState([...pages?.pages]);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr();
    const { foundation, dispatch } = useFoundationContext();

    const requiredFields = [
        'alt',
        'title',
        'subtitle',
        'text1',
        'buttonText',
        'buttonTextEng',
        'buttonTextFra',
        'buttonTextGer',
        'buttonTextDut',
        'buttonLink'
    ];

    const handleButtonUpdate = (e) => {
        const selectedValue = JSON.parse(e.target.value);

        setUpdate(prevState => ({
            ...prevState,
            buttonText: selectedValue.name,
            buttonTextEng: selectedValue.nameEng,
            buttonTextFra: selectedValue.nameFra,
            buttonTextGer: selectedValue.nameGer,
            buttonTextDut: selectedValue.nameDut,
            buttonLink: selectedValue.link
        }));
    };

    const handleOnChange = (e) => {
        const { id, value } = e.target;

        setUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const generateUniqueFileName = (file) => {
        const timestamp = Date.now();
        const randomNumber = Math.floor(Math.random() * 10000);
        const extension = file.name.split('.').pop();

        return `${timestamp}_${randomNumber}.${extension}`;
    };

    const handlePhotoInput = (e) => {
        const file = e.target.files[0];
        const id = e.target.id;

        const newFileName = generateUniqueFileName(file);
        const renamedFile = new File([file], newFileName, { type: file.type });

        setUpdate(prevState => ({
            ...prevState,
            [id]: renamedFile
        }));
    };

    const handleSubmit = async () => {
        for (const field of requiredFields) {
            if (!update[field] || update[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'foundation');
        formData.append('action', 'update');
        formData.append('id', update.id);
        formData.append('photo', update.photo);
        formData.append('alt', update.alt);
        formData.append('background', update.background);
        formData.append('title', update.title);
        formData.append('subtitle', update.subtitle);
        formData.append('text1', update.text1);
        formData.append('titleEng', update.titleEng);
        formData.append('subtitleEng', update.subtitleEng);
        formData.append('textEng', update.textEng);
        formData.append('titleFra', update.titleFra);
        formData.append('subtitleFra', update.subtitleFra);
        formData.append('textFra', update.textFra);
        formData.append('titleGer', update.titleGer);
        formData.append('subtitleGer', update.subtitleGer);
        formData.append('textGer', update.textGer);
        formData.append('titleDut', update.titleDut);
        formData.append('subtitleDut', update.subtitleDut);
        formData.append('textDut', update.textDut);
        formData.append('buttonText', update.buttonText);
        formData.append('buttonTextEng', update.buttonTextEng);
        formData.append('buttonTextFra', update.buttonTextFra);
        formData.append('buttonTextGer', update.buttonTextGer);
        formData.append('buttonTextDut', update.buttonTextDut);
        formData.append('buttonLink', update.buttonLink);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setUpdate(null);
            setLang(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getFoundation = async () => {
        const formData = new FormData();
        formData.append('module', 'foundation');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            dispatch({ type: 'SET_FOUNDATION', payload: response[0] });
        };
    };

    useEffect(() => {
        getFoundation();
    }, [status]);

    return (
        <div className="container-xxl" >
            <PageHeader headerTitle="Temel Bölüm" />
            <div className="row align-items-center">
                <div className="col-lg-12 col-md-12 flex-column" >
                    {
                        foundation &&
                        <div className="row g-3 gy-5 py-3 row-deck">
                            <div className="col-lg-4 col-md-6 col-12 mt-1">
                                <div className="card d-flex justify-content-center align-items-center p-4">
                                    <img
                                        src={process.env.PUBLIC_URL + `/uploads/foundation/${foundation.photoName}`}
                                        className="mb-4"
                                        style={{
                                            objectFit: "cover",
                                            width: "100%",
                                            borderRadius: "6px"
                                        }}
                                    />

                                    <img
                                        src={process.env.PUBLIC_URL + `/uploads/foundation/${foundation.backgroundName}`}
                                        style={{
                                            objectFit: "cover",
                                            width: "100%",
                                            borderRadius: "6px"
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-6 col-12 mt-1">
                                <div className="card customFoundation">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="lesson_name">
                                                <p className="mb-0 mb-2">{foundation.subtitle}</p>
                                                <h4 className="mb-0 fw-bold fs-4 mb-2">{foundation.title}</h4>
                                                <div className="mb-0 mb-2 dangerousText" dangerouslySetInnerHTML={{ __html: foundation.text1 }} />
                                            </div>
                                        </div>
                                        <div className="row g-2 pt-4">
                                            <div className="col-12">
                                                <div className="d-flex align-items-center">
                                                    <div className="text-primary">{"Buton => "}</div>
                                                    <span className="ms-2">{foundation.buttonText}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-2 pt-4">
                                            <div className="col-12">
                                                <div className="d-flex align-items-center">
                                                    <div className="text-primary">{"SEO => "}</div>
                                                    <span className="ms-2">{foundation.alt}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-hover-show" style={{ float: "right" }}>
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary"
                                                title="Türkçe Güncelleme"
                                                onClick={() => { setUpdate(foundation); setLang("tur"); }}
                                            >
                                                <i className="icofont-edit text-success"></i>
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary"
                                                title="İngilizce Güncelleme"
                                                onClick={() => { setUpdate(foundation); setLang("eng"); }}
                                            >
                                                <img src={process.env.PUBLIC_URL + `/flags/united-kingdom.png`} style={{ width: "15px" }} />
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary"
                                                title="Fransızca Güncelleme"
                                                onClick={() => { setUpdate(foundation); setLang("fra"); }}
                                            >
                                                <img src={process.env.PUBLIC_URL + `/flags/france.png`} style={{ width: "15px" }} />
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary"
                                                title="Almanca Güncelleme"
                                                onClick={() => { setUpdate(foundation); setLang("ger"); }}
                                            >
                                                <img src={process.env.PUBLIC_URL + `/flags/germany.png`} style={{ width: "15px" }} />
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary"
                                                title="Flemenkçe Güncelleme"
                                                onClick={() => { setUpdate(foundation); setLang("dut"); }}
                                            >
                                                <img src={process.env.PUBLIC_URL + `/flags/netherlands.png`} style={{ width: "15px" }} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Modal
                show={lang === "tur"}
                onHide={() => {
                    setUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {update &&
                        <>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Başlık</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="title"
                                    value={update && update.title}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "title" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "title" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="subtitle" className="form-label">Üst Başlık</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="subtitle"
                                    value={update && update.subtitle}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "subtitle" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "subtitle" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="text1" className="form-label">Yazı</label>
                                <textarea
                                    className="form-control"
                                    id="text1"
                                    rows="5"
                                    value={update && update.text1}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "text1" ? "red" : "var(--border-color)"}` }}
                                ></textarea>
                                {error.field === "text1" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="g-3 mb-3">
                                <label htmlFor="button" className="form-label">Buton</label>
                                <select
                                    id="button"
                                    className="form-select"
                                    onChange={handleButtonUpdate}
                                    style={{ borderColor: `${error.field === "button" ? "red" : "var(--border-color)"}` }}
                                >
                                    <option value="" hidden>{update.buttonText ? update.buttonText : "Seçiniz..."}</option>
                                    {pagesData.map((option) => (
                                        <option
                                            key={option.name}
                                            value={JSON.stringify({
                                                name: option.name,
                                                nameEng: option.nameEng,
                                                nameFra: option.nameFra,
                                                nameGer: option.nameGer,
                                                nameDut: option.nameDut,
                                                link: option.link
                                            })}
                                        >
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                                {error.field === "buttonText" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="alt" className="form-label">
                                    {"Seo Kelimeler "}
                                    <span className="text-danger">
                                        (Lütfen 5 Kelimeden Fazla Yazmayınız!)
                                    </span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="alt"
                                    value={update && update.alt}
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${error.field === "alt" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "alt" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="photo" className="form-label">Fotoğraf Yükle</label>
                                <input
                                    className="form-control"
                                    type="file"
                                    id="photo"
                                    onChange={handlePhotoInput}
                                />
                                <div className="form-label" style={{ color: "red" }}>
                                    {"Bu alanın geçerli görsel öğe boyutu: 1242x828"}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="background" className="form-label">Arka Plan Fotoğrafı Yükle</label>
                                <input
                                    className="form-control"
                                    type="file"
                                    id="background"
                                    onChange={handlePhotoInput}
                                />
                                <div className="form-label" style={{ color: "red" }}>
                                    {"Bu alanın geçerli görsel öğe boyutu: 3059x1912"}
                                </div>
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "eng"}
                onHide={() => {
                    setUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">İngilizce Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleEng" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleEng"
                            value={update && update.titleEng}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "titleEng" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleEng" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="subtitleEng" className="form-label">Üst Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="subtitleEng"
                            value={update && update.subtitleEng}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "subtitleEng" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "subtitleEng" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="textEng" className="form-label">Yazı</label>
                        <textarea
                            className="form-control"
                            id="textEng"
                            rows="5"
                            value={update && update.textEng}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "textEng" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textEng" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "fra"}
                onHide={() => {
                    setUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Fransızca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleFra" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleFra"
                            value={update && update.titleFra}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "titleFra" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleFra" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="subtitleFra" className="form-label">Üst Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="subtitleFra"
                            value={update && update.subtitleFra}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "subtitleFra" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "subtitleFra" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="textFra" className="form-label">Yazı</label>
                        <textarea
                            className="form-control"
                            id="textFra"
                            rows="5"
                            value={update && update.textFra}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "textFra" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textFra" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "ger"}
                onHide={() => {
                    setUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Almanca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleGer" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleGer"
                            value={update && update.titleGer}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "titleGer" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleGer" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="subtitleGer" className="form-label">Üst Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="subtitleGer"
                            value={update && update.subtitleGer}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "subtitleGer" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "subtitleGer" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="textGer" className="form-label">Yazı</label>
                        <textarea
                            className="form-control"
                            id="textGer"
                            rows="5"
                            value={update && update.textGer}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "textGer" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textGer" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "dut"}
                onHide={() => {
                    setUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Flemenkçe Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleDut" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleDut"
                            value={update && update.titleDut}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "titleDut" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleDut" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="subtitleDut" className="form-label">Üst Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="subtitleDut"
                            value={update && update.subtitleDut}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "subtitleDut" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "subtitleDut" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="textDut" className="form-label">Yazı</label>
                        <textarea
                            className="form-control"
                            id="textDut"
                            rows="5"
                            value={update && update.textDut}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "textDut" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textDut" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default Foundation;