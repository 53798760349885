import { createContext, useReducer } from "react";

export const BulgurContext = createContext();

export const bulgurReducer = (state, action) => {
    switch (action.type) {
        case 'SET_BULGUR':
            return {
                bulgur: action.payload
            }
        case 'CREATE_BULGUR':
            return {
                bulgur: [action.payload, ...state.bulgur]
            }
        case 'DELETE_BULGUR':
            return {
                bulgur: state.bulgur.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const BulgurContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(bulgurReducer, {
        bulgur: null
    });

    return (
        <BulgurContext.Provider value={{ ...state, dispatch }}>
            {children}
        </BulgurContext.Provider>
    );
};