import { createContext, useReducer } from "react";

export const MediaContext = createContext();

export const mediaReducer = (state, action) => {
    switch (action.type) {
        case 'SET_MEDIA':
            return {
                media: action.payload
            }
        case 'CREATE_MEDIA':
            return {
                media: [action.payload, ...state.media]
            }
        case 'DELETE_MEDIA':
            return {
                media: state.media.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const MediaContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(mediaReducer, {
        media: null
    });

    return (
        <MediaContext.Provider value={{ ...state, dispatch }}>
            {children}
        </MediaContext.Provider>
    );
};