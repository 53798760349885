import { createContext, useReducer } from "react";

export const PrivacyContext = createContext();

export const privacyReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PRIVACY':
            return {
                privacy: action.payload
            }
        case 'CREATE_PRIVACY':
            return {
                privacy: [action.payload, ...state.privacy]
            }
        case 'DELETE_PRIVACY':
            return {
                privacy: state.privacy.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const PrivacyContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(privacyReducer, {
        privacy: null
    });

    return (
        <PrivacyContext.Provider value={{ ...state, dispatch }}>
            {children}
        </PrivacyContext.Provider>
    );
};