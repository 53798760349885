import { createContext, useReducer } from "react";

export const RoyalContext = createContext();

export const royalReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ROYAL':
            return {
                royal: action.payload
            }
        case 'CREATE_ROYAL':
            return {
                royal: [action.payload, ...state.royal]
            }
        case 'DELETE_ROYAL':
            return {
                royal: state.royal.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const RoyalContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(royalReducer, {
        royal: null
    });

    return (
        <RoyalContext.Provider value={{ ...state, dispatch }}>
            {children}
        </RoyalContext.Provider>
    );
};