import { CookieContext } from "../context/CookieContext";
import { useContext } from "react";

export const useCookieContext = () => {
    const context = useContext(CookieContext);

    if (!context) {
        return Error('useCookieContext must be used inside an CookieContextProvider');
    };

    return context;
};