import { createContext, useReducer } from "react";

export const AwardsContext = createContext();

export const awardsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_AWARDS':
            return {
                awards: action.payload
            }
        case 'CREATE_AWARDS':
            return {
                awards: [action.payload, ...state.awards]
            }
        case 'DELETE_AWARDS':
            return {
                awards: state.awards.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const AwardsContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(awardsReducer, {
        awards: null
    });

    return (
        <AwardsContext.Provider value={{ ...state, dispatch }}>
            {children}
        </AwardsContext.Provider>
    );
};