import { WhyPhotosContext } from "../context/WhyPhotosContext";
import { useContext } from "react";

export const useWhyPhotosContext = () => {
    const context = useContext(WhyPhotosContext);

    if (!context) {
        return Error('useWhyPhotosContext must be used inside an WhyPhotosContextProvider');
    };

    return context;
};