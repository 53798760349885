import { SustainabilityTextContext } from "../context/SustainabilityTextContext";
import { useContext } from "react";

export const useSustainabilityTextContext = () => {
    const context = useContext(SustainabilityTextContext);

    if (!context) {
        return Error('useSustainabilityTextContext must be used inside an SustainabilityTextContextProvider');
    };

    return context;
};