import { CuriousThingsContext } from "../context/CuriousThingsContext";
import { useContext } from "react";

export const useCuriousThingsContext = () => {
    const context = useContext(CuriousThingsContext);

    if (!context) {
        return Error('useCuriousThingsContext must be used inside an CuriousThingsContextProvider');
    };

    return context;
};