import { BakliyatContext } from "../context/BakliyatContext";
import { useContext } from "react";

export const useBakliyatContext = () => {
    const context = useContext(BakliyatContext);

    if (!context) {
        return Error('useBakliyatContext must be used inside an BakliyatContextProvider');
    };

    return context;
};