import { createContext, useReducer } from "react";

export const CookieContext = createContext();

export const cookieReducer = (state, action) => {
    switch (action.type) {
        case 'SET_COOKIE':
            return {
                cookie: action.payload
            }
        case 'CREATE_COOKIE':
            return {
                cookie: [action.payload, ...state.cookie]
            }
        case 'DELETE_COOKIE':
            return {
                cookie: state.cookie.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const CookieContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cookieReducer, {
        cookie: null
    });

    return (
        <CookieContext.Provider value={{ ...state, dispatch }}>
            {children}
        </CookieContext.Provider>
    );
};