import { AwardsContext } from "../context/AwardsContext";
import { useContext } from "react";

export const useAwardsContext = () => {
    const context = useContext(AwardsContext);

    if (!context) {
        return Error('useAwardsContext must be used inside an AwardsContextProvider');
    };

    return context;
};