import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PageHeader from "../../components/common/PageHeader";
import { useXhr } from "hooks/useXhr";
import { useContactContext } from "hooks/useContactContext";
import { Button, Spinner } from "react-bootstrap";

function Contact() {
    //States
    const [status, setStatus] = useState(false);
    const [isUpdate, setIsUpdate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [lang, setLang] = useState(null);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr()
    const { contact, dispatch } = useContactContext();

    const requiredFields = ['name1', 'phone', 'email', 'address', 'addressLink'];

    const handleOnChange = (e) => {
        const { id, value } = e.target;

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleUpdate = async () => {
        for (const field of requiredFields) {
            if (!isUpdate[field] || isUpdate[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'contact');
        formData.append('action', 'update');
        formData.append('id', isUpdate.id);
        formData.append('name1', isUpdate.name1);
        formData.append('nameEng', isUpdate.nameEng);
        formData.append('nameFra', isUpdate.nameFra);
        formData.append('nameGer', isUpdate.nameGer);
        formData.append('nameDut', isUpdate.nameDut);
        formData.append('phone', isUpdate.phone);
        formData.append('email', isUpdate.email);
        formData.append('address', isUpdate.address);
        formData.append('addressEng', isUpdate.addressEng);
        formData.append('addressFra', isUpdate.addressFra);
        formData.append('addressGer', isUpdate.addressGer);
        formData.append('addressDut', isUpdate.addressDut);
        formData.append('addressLink', isUpdate.addressLink);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setIsUpdate(null);
            setLang(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getContact = async () => {
        const formData = new FormData();
        formData.append('module', 'contact');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            dispatch({ type: 'SET_CONTACT', payload: response });
        };
    };

    useEffect(() => {
        getContact();
    }, [status]);

    //Columns
    var columnT = ""

    columnT = [
        {
            name: "ADRES ADI",
            selector: (row) => row.name1,
            sortable: true,
            width: "120px",
            cell: (row) => <a href="#" className="fw-bold text-primary">{row.name1}</a>
        },
        {
            name: "TELEFON",
            selector: (row) => row.phone,
            sortable: true,
            width: "110px"
        },
        {
            name: "E-POSTA",
            selector: (row) => row.email,
            sortable: true,
            width: "160px"
        },
        {
            name: "ADRES",
            selector: (row) => row.address,
            sortable: true
        },
        {
            name: "LİNK",
            selector: (row) => row.addressLink,
            sortable: true,
            width: "340px"
        },
        {
            name: "AKSİYON",
            selector: (row) => { },
            sortable: true,
            cell: (row) => <div className="btn-group" role="group" aria-label="Basic outlined example">
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Türkçe Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("tur"); }}
                >
                    <i className="icofont-edit text-success"></i>
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="İngilizce Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("eng"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/united-kingdom.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Fransızca Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("fra"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/france.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Almanca Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("ger"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/germany.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Flemenkçe Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("dut"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/netherlands.png`} style={{ width: "15px" }} />
                </button>
            </div>
        }
    ]

    return (
        <div className="container-xxl">
            <PageHeader headerTitle="İletişim" />
            <div className="row clearfix g-3">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            {contact &&
                                <DataTable
                                    title="İletişim"
                                    columns={columnT}
                                    data={contact}
                                    defaultSortField="title"
                                    pagination
                                    selectableRows={false}
                                    className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                                    highlightOnHover={true}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={lang === "tur"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Adresi Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="phone" className="form-label">Telefon</label>
                        <input
                            type="text"
                            className="form-control"
                            id="phone"
                            value={isUpdate && isUpdate.phone}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "phone" ? "red" : "var(--border-color)"}` }}
                        />
                        {error.field === "phone" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">E-Posta</label>
                        <input
                            type="text"
                            className="form-control"
                            id="email"
                            value={isUpdate && isUpdate.email}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "email" ? "red" : "var(--border-color)"}` }}
                        />
                        {error.field === "email" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="address" className="form-label">Adres</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            value={isUpdate && isUpdate.address}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "address" ? "red" : "var(--border-color)"}` }}
                        />
                        {error.field === "address" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="addressLink" className="form-label">Adres Link</label>
                        <input
                            type="text"
                            className="form-control"
                            id="addressLink"
                            value={isUpdate && isUpdate.addressLink}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "addressLink" ? "red" : "var(--border-color)"}` }}
                        />
                        {error.field === "addressLink" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "eng"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">İngilizce Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="addressEng" className="form-label">Adres</label>
                        <input
                            type="text"
                            className="form-control"
                            id="addressEng"
                            value={isUpdate && isUpdate.addressEng}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "addressEng" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "addressEng" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "fra"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Fransızca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="addressFra" className="form-label">Adres</label>
                        <input
                            type="text"
                            className="form-control"
                            id="addressFra"
                            value={isUpdate && isUpdate.addressFra}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "addressFra" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "addressFra" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "ger"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Almanca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="addressGer" className="form-label">Adres</label>
                        <input
                            type="text"
                            className="form-control"
                            id="addressGer"
                            value={isUpdate && isUpdate.addressGer}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "addressGer" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "addressGer" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "dut"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Flemenkçe Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="addressDut" className="form-label">Adres</label>
                        <input
                            type="text"
                            className="form-control"
                            id="addressDut"
                            value={isUpdate && isUpdate.addressDut}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "addressDut" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "addressDut" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Contact;