import { PirincContext } from "../context/PirincContext";
import { useContext } from "react";

export const usePirincContext = () => {
    const context = useContext(PirincContext);

    if (!context) {
        return Error('usePirincContext must be used inside an PirincContextProvider');
    };

    return context;
};