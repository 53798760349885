import { createContext, useReducer } from "react";

export const InnerpagesContext = createContext();

export const innerpagesReducer = (state, action) => {
    switch (action.type) {
        case 'SET_INNERPAGES':
            return {
                innerpages: action.payload
            }
        case 'CREATE_INNERPAGES':
            return {
                innerpages: [action.payload, ...state.innerpages]
            }
        case 'DELETE_INNERPAGES':
            return {
                innerpages: state.innerpages.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const InnerpagesContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(innerpagesReducer, {
        innerpages: null
    });

    return (
        <InnerpagesContext.Provider value={{ ...state, dispatch }}>
            {children}
        </InnerpagesContext.Provider>
    );
};