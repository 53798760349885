import { RoyalContext } from "../context/RoyalContext";
import { useContext } from "react";

export const useRoyalContext = () => {
    const context = useContext(RoyalContext);

    if (!context) {
        return Error('useRoyalContext must be used inside an RoyalContextProvider');
    };

    return context;
};