import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Sidebar from "./components/common/Sidebar";
import AuthIndex from "./screens/AuthIndex";
import MainIndex from "./screens/MainIndex";
import { useAuthContext } from "./hooks/useAuthContext";
import { useTokenErrorContext } from "hooks/useTokenErrorContext";
import { useLogout } from "hooks/useLogout";

function App(props) {
  function activekey() {
    var res = window.location.pathname;
    const parts = res.split("/");
    // Eğer başında bir "/" karakteri varsa kaldırın
    if (parts[0] === "") {
      parts.shift();
    }
    return "/" + parts.join("/");
  }

  const { user } = useAuthContext();
  const { tokenError } = useTokenErrorContext();
  const { logout } = useLogout();

  useEffect(() => {
    if (tokenError === "Token Unsuccessfull!") {
      logout();
    };
  }, [tokenError]);

  return (
    <div id="mytask-layout" className="theme-indigo">
      <Routes>
        <Route exact path="/" element={<Navigate to="/main/big-sliders" />} />
        <Route path="/auth/*" element={!user ? <AuthIndex /> : <Navigate to="/main/big-sliders" />} />
        <Route
          path="/main/*"
          element={user ?
            <>
              <Sidebar activekey={activekey()} history={props.history} />
              <MainIndex activekey={activekey()} />
            </> :
            <Navigate to="/auth/sign-in" />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
