import { TimelineListContext } from "../context/TimelineListContext";
import { useContext } from "react";

export const useTimelineListContext = () => {
    const context = useContext(TimelineListContext);

    if (!context) {
        return Error('useTimelineListContext must be used inside an TimelineListContextProvider');
    };

    return context;
};