import { createContext, useReducer } from "react";

export const AboutVideoContext = createContext();

export const aboutVideoReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ABOUT_VIDEO':
            return {
                aboutVideo: action.payload
            }
        case 'CREATE_ABOUT_VIDEO':
            return {
                aboutVideo: [action.payload, ...state.aboutVideo]
            }
        case 'DELETE_ABOUT_VIDEO':
            return {
                aboutVideo: state.aboutVideo.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const AboutVideoContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(aboutVideoReducer, {
        aboutVideo: null
    });

    return (
        <AboutVideoContext.Provider value={{ ...state, dispatch }}>
            {children}
        </AboutVideoContext.Provider>
    );
};