import { SlidersContext } from "../context/SlidersContext";
import { useContext } from "react";

export const useSlidersContext = () => {
    const context = useContext(SlidersContext);

    if (!context) {
        return Error('useSlidersContext must be used inside an SlidersContextProvider');
    };

    return context;
};