import { createContext, useReducer } from "react";

export const SlidersContext = createContext();

export const slidersReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SLIDERS':
            return {
                sliders: action.payload
            }
        case 'CREATE_SLIDERS':
            return {
                sliders: [action.payload, ...state.sliders]
            }
        case 'DELETE_SLIDERS':
            return {
                sliders: state.sliders.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const SlidersContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(slidersReducer, {
        sliders: null
    });

    return (
        <SlidersContext.Provider value={{ ...state, dispatch }}>
            {children}
        </SlidersContext.Provider>
    );
};