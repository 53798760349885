import { BulgurContext } from "../context/BulgurContext";
import { useContext } from "react";

export const useBulgurContext = () => {
    const context = useContext(BulgurContext);

    if (!context) {
        return Error('useBulgurContext must be used inside an BulgurContextProvider');
    };

    return context;
};