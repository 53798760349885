import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PageHeader from "../../components/common/PageHeader";
import { useXhr } from "hooks/useXhr";
import { useSustainabilityPhotosContext } from "hooks/useSustainabilityPhotosContext";
import { Button, Spinner } from "react-bootstrap";

function SustainabilityPhotos() {
    //States
    const [status, setStatus] = useState(false);
    const [photosUpdate, setPhotosUpdate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr();
    const { sustainabilityPhotos, dispatch } = useSustainabilityPhotosContext();

    const requiredFields = ['alt'];

    //Functions
    const handleOnChange = (e) => {
        const { id, value } = e.target;

        setPhotosUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const generateUniqueFileName = (file) => {
        const timestamp = Date.now();
        const randomNumber = Math.floor(Math.random() * 10000);
        const extension = file.name.split('.').pop();

        return `${timestamp}_${randomNumber}.${extension}`;
    };

    const handleUpdate = (e) => {
        const file = e.target.files[0];
        const id = e.target.id;

        const newFileName = generateUniqueFileName(file);

        const newFile = new File([file], newFileName, { type: file.type });

        setPhotosUpdate(prevState => ({
            ...prevState,
            [id]: newFile
        }));
    };

    const handlePhotosSubmit = async () => {
        for (const field of requiredFields) {
            if (!photosUpdate[field] || photosUpdate[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'sustainability_photos');
        formData.append('action', 'update');
        formData.append('id', photosUpdate.id);
        formData.append('photo', photosUpdate.photo);
        formData.append('alt', photosUpdate.alt);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setPhotosUpdate(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getSustainabilityPhotos = async () => {
        const formData = new FormData();
        formData.append('module', 'sustainability_photos');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            dispatch({ type: 'SET_SUSTAINABILITY_PHOTOS', payload: response.data });
        };
    };

    useEffect(() => {
        getSustainabilityPhotos();
    }, [status]);

    if (sustainabilityPhotos) {
        return (
            <div className="container-xxl">
                <PageHeader headerTitle="Sürdürülebilirlik Fotoğraf Bölümü" />
                <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12 flex-column">
                        <div className="row g-3 gy-5 py-3 row-deck">

                            <div className="col-12">
                                <div className="card customSliders" style={{ marginTop: "0px" }}>
                                    <div className="card-body" style={{ marginTop: "0px" }}>
                                        <div className="row d-flex justify-content-center" style={{ height: "70vh" }}>
                                            <div className="col-lg-4 col-sm-6">
                                                <div className="service-sub-image" style={{ position: "relative" }}>
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL + `/uploads/sustainability_photos/${sustainabilityPhotos[0].photoName}`
                                                        }
                                                        alt=""
                                                        style={{ objectFit: "cover" }}
                                                    />
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary sustainabilityPhotos"
                                                        onClick={() => setPhotosUpdate(sustainabilityPhotos[0])}
                                                    >
                                                        <i className="icofont-edit text-success"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6">
                                                <div className="service-sub-image">
                                                    <div className="twoPhoto" style={{ paddingBottom: "calc(var(--bs-gutter-x) * 0.5)", position: "relative" }}>
                                                        <img
                                                            src={process.env.PUBLIC_URL + `/uploads/sustainability_photos/${sustainabilityPhotos[1].photoName}`}
                                                            alt=""
                                                            style={{ objectFit: "cover" }}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-secondary sustainabilityPhotos"
                                                            onClick={() => setPhotosUpdate(sustainabilityPhotos[1])}
                                                        >
                                                            <i className="icofont-edit text-success"></i>
                                                        </button>
                                                    </div>
                                                    <div className="twoPhoto" style={{ paddingTop: "calc(var(--bs-gutter-x) * 0.5)", position: "relative" }}>
                                                        <img
                                                            src={process.env.PUBLIC_URL + `/uploads/sustainability_photos/${sustainabilityPhotos[2].photoName}`}
                                                            alt=""
                                                            style={{ objectFit: "cover" }}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-secondary sustainabilityPhotos"
                                                            onClick={() => setPhotosUpdate(sustainabilityPhotos[2])}
                                                        >
                                                            <i className="icofont-edit text-success"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6">
                                                <div className="service-sub-image" style={{ position: "relative" }}>
                                                    <img
                                                        src={process.env.PUBLIC_URL + `/uploads/sustainability_photos/${sustainabilityPhotos[3].photoName}`}
                                                        alt=""
                                                        style={{ objectFit: "cover" }}
                                                    />
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary sustainabilityPhotos"
                                                        onClick={() => setPhotosUpdate(sustainabilityPhotos[3])}
                                                    >
                                                        <i className="icofont-edit text-success"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Modal
                    show={photosUpdate}
                    onHide={() => {
                        setPhotosUpdate(null);
                        setError({
                            field: null,
                            note: null
                        });
                        setIsLoading(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold">Fotoğraf Düzenle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {photosUpdate &&
                            <>
                                <div className="mb-3">
                                    <label htmlFor="alt" className="form-label">
                                        {"Seo Kelimeler "}
                                        <span className="text-danger">
                                            (Lütfen 5 Kelimeden Fazla Yazmayınız!)
                                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="alt"
                                        value={photosUpdate && photosUpdate.alt}
                                        onChange={handleOnChange}
                                        style={{ borderColor: `${error.field === "alt" ? "red" : "var(--border-color)"}` }}
                                    />
                                    {error.field === "alt" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="photo" className="form-label">Fotoğraf Yükle</label>
                                    <input
                                        className="form-control"
                                        type="file"
                                        id="photo"
                                        onChange={handleUpdate}
                                    />
                                    {
                                        photosUpdate.id === "1" || photosUpdate.id === "4" ?
                                            <div className="form-label" style={{ color: "red" }}>
                                                {"Bu alanın geçerli görsel öğe boyutu: 796x572"}
                                            </div> :
                                            <div className="form-label" style={{ color: "red" }}>
                                                {"Bu alanın geçerli görsel öğe boyutu: 3696x5600"}
                                            </div>
                                    }
                                </div>
                            </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            className="me-1"
                            onClick={() => handlePhotosSubmit()}
                            disabled={isLoading}
                        >
                            {
                                isLoading ?
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                        Yükleniyor...
                                    </> :
                                    <>
                                        Kaydet
                                    </>
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default SustainabilityPhotos;