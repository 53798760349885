import { createContext, useReducer } from "react";

export const YieldContext = createContext();

export const yield1Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_YIELD':
            return {
                yield1: action.payload
            }
        case 'CREATE_YIELD':
            return {
                yield1: [action.payload, ...state.yield1]
            }
        case 'DELETE_YIELD':
            return {
                yield1: state.yield1.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const YieldContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(yield1Reducer, {
        yield1: null
    });

    return (
        <YieldContext.Provider value={{ ...state, dispatch }}>
            {children}
        </YieldContext.Provider>
    );
};