import { QualityContext } from "../context/QualityContext";
import { useContext } from "react";

export const useQualityContext = () => {
    const context = useContext(QualityContext);

    if (!context) {
        return Error('useQualityContext must be used inside an QualityContextProvider');
    };

    return context;
};