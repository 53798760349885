import { createContext, useReducer } from "react";

export const WhyTextContext = createContext();

export const whyTextReducer = (state, action) => {
    switch (action.type) {
        case 'SET_WHY_TEXT':
            return {
                whyText: action.payload
            }
        case 'CREATE_WHY_TEXT':
            return {
                whyText: [action.payload, ...state.whyText]
            }
        case 'DELETE_WHY_TEXT':
            return {
                whyText: state.whyText.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const WhyTextContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(whyTextReducer, {
        whyText: null
    });

    return (
        <WhyTextContext.Provider value={{ ...state, dispatch }}>
            {children}
        </WhyTextContext.Provider>
    );
};