import React from "react";
import loginImg from "../../assets/images/login-img.svg";
import Logo from "../../assets/images/logos/ReisLogo.svg"

function LeftSide (){
    
        return(
            <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center rounded-lg auth-h100">
                <div style={{maxWidth: "25rem"}}>
                    <div className="text-center mb-2">
                        <img src={Logo} alt="" style={{width: "70%"}} />
                    </div>
                    {/* <div className="mb-5">
                        <h2 className="color-900 text-center">REİS OLSUN AFİYET OLSUN</h2>
                    </div> */}
                    <div className="">
                        <img src={loginImg} alt="login-img" />
                    </div>
                </div>
            </div>
        )
    }


export default LeftSide;