import { createContext, useReducer } from "react";

export const FoundationContext = createContext();

export const foundationReducer = (state, action) => {
    switch (action.type) {
        case 'SET_FOUNDATION':
            return {
                foundation: action.payload
            }
        case 'CREATE_FOUNDATION':
            return {
                foundation: [action.payload, ...state.foundation]
            }
        case 'DELETE_FOUNDATION':
            return {
                foundation: state.foundation.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const FoundationContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(foundationReducer, {
        foundation: null
    });

    return (
        <FoundationContext.Provider value={{ ...state, dispatch }}>
            {children}
        </FoundationContext.Provider>
    );
};