import { HeadersContext } from "../context/HeadersContext";
import { useContext } from "react";

export const useHeadersContext = () => {
    const context = useContext(HeadersContext);

    if (!context) {
        return Error('useHeadersContext must be used inside an HeadersContextProvider');
    };

    return context;
};