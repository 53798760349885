import { SocialResponsibilityContext } from "../context/SocialResponsibilityContext";
import { useContext } from "react";

export const useSocialResponsibilityContext = () => {
    const context = useContext(SocialResponsibilityContext);

    if (!context) {
        return Error('useSocialResponsibilityContext must be used inside an SocialResponsibilityContextProvider');
    };

    return context;
};