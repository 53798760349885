import { PrivacyContext } from "../context/PrivacyContext";
import { useContext } from "react";

export const usePrivacyContext = () => {
    const context = useContext(PrivacyContext);

    if (!context) {
        return Error('usePrivacyContext must be used inside an PrivacyContextProvider');
    };

    return context;
};