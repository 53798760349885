import { KvkkContext } from "../context/KvkkContext";
import { useContext } from "react";

export const useKvkkContext = () => {
    const context = useContext(KvkkContext);

    if (!context) {
        return Error('useKvkkContext must be used inside an KvkkContextProvider');
    };

    return context;
};