import { WhyTextContext } from "../context/WhyTextContext";
import { useContext } from "react";

export const useWhyTextContext = () => {
    const context = useContext(WhyTextContext);

    if (!context) {
        return Error('useWhyTextContext must be used inside an WhyTextContextProvider');
    };

    return context;
};