import { ContactContext } from "../context/ContactContext";
import { useContext } from "react";

export const useContactContext = () => {
    const context = useContext(ContactContext);

    if (!context) {
        return Error('useContactContext must be used inside an ContactContextProvider');
    };

    return context;
};