import { createContext, useReducer } from "react";

export const SustainabilityTextContext = createContext();

export const sustainabilityTextReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SUSTAINABILITY_TEXT':
            return {
                sustainabilityText: action.payload
            }
        case 'CREATE_SUSTAINABILITY_TEXT':
            return {
                sustainabilityText: [action.payload, ...state.sustainabilityText]
            }
        case 'DELETE_SUSTAINABILITY_TEXT':
            return {
                sustainabilityText: state.sustainabilityText.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const SustainabilityTextContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(sustainabilityTextReducer, {
        sustainabilityText: null
    });

    return (
        <SustainabilityTextContext.Provider value={{ ...state, dispatch }}>
            {children}
        </SustainabilityTextContext.Provider>
    );
};